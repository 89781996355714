<template>
  <section class="navs d-flex justify-content-end">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#">Plataforma</a>
          </li>
          <li class="nav-item active">
            <router-link class="nav-link" to="/login">Login</router-link>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Responsive -->
  </section>
</template>

<script>
export default {
  name: "NavComponent",
  data: () => ({}),
  computed: {},
  methods: {}
};
</script>

<style scoped lang="scss">
@import "./Nav.scss";
</style>
