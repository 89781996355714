<template>
  <b-container fluid>
    <b-row style="height: 100vh">
      <b-col md="5" lg="4" class="p-0 d-none d-sm-block">
        <SideBarComponent />
      </b-col>
      <b-col md="7" lg="8" class="py-4">
        <NavComponent />
        <main class="wrapper d-flex align-items-center">
          <div class="w-100">
            <h2 class="h3 text-md-left font-semi-bold mb-2r">
              Recuperar contraseña
            </h2>
            <AlertInfoComponent
              title="Información recuperar contraseña"
              :description="recoveryPassowordResponse"
              v-if="isResponseAlert"
            />

            <el-form :model="formRecovery" :rules="rules" ref="formRecovery">
              <el-form-item prop="email">
                <slot name="label">
                  <label class="font-semi-bold">Correo electrónico</label>
                </slot>
                <el-input v-model="formRecovery.email"></el-input>
              </el-form-item>

              <div class="form-group text-center">
                <button
                  class="btn md__btn-primary px-5 my-4"
                  @click.prevent="onSubmit()"
                >
                  Recuperar
                </button>
              </div>
            </el-form>
          </div>
        </main>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SideBarComponent from "../../components/SideBar/SideBar";
import NavComponent from "@/components/Nav/Nav";
import AlertInfoComponent from "@/components/Alerts/Info";
import AuthServices from "../../services/AuthServices";
import Validate from "@/assets/validate";

export default {
  name: "RecoveryPasswordPage",
  data: () => ({
    formRecovery: {
      email: ""
    },
    rules: {
      email: [{ ...Validate.rules.required(), ...Validate.rules.email() }]
    },
    isResponseAlert: false,
    recoveryPassowordResponse: ""
  }),
  methods: {
    onSubmit() {
      this.$refs.formRecovery.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          this.isResponseAlert = false;
          try {
            let response = await AuthServices.passwordRecovery(
              this.formRecovery
            ).finally(() => {
              this.$refs.formRecovery.resetFields();
              this.$store.dispatch("toggleRequestLoading");
            });

            if (response) {
              this.recoveryPassowordResponse = response.data.message;
              this.isResponseAlert = true;
            }
          } catch (error) {
            this.recoveryPassowordResponse =
              "¡Ha ocurrido un imprevisto!, por favor intenta de nuevo";
            this.isResponseAlert = true;
          }
        }
      });
    }
  },
  components: {
    SideBarComponent,
    NavComponent,
    AlertInfoComponent
  }
};
</script>

<style scoped lang="scss">
@import "./RecoveryPassword.scss";
</style>
