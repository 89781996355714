<template>
  <aside
    :style="bgSiderBar"
    class="d-flex flex-column justify-content-between text-white m-0 p-3 p-md-5"
    id="siderbar"
  >
    <div class="wrapper_body-sidebar">
      <h3 class="h3">
        Administra tus propuestas y presupuestos de forma organizada
      </h3>
      <p class="pt-3">
        Acceso ilimitado. Colaboración de usuarios.Personalización de plantillas
      </p>

      <p class="font-weight-bold mt-4">Empresas que confian en nosotros</p>
      <figure class="d-flex justify-content-between">
        <img :src="icoBusiness1" class="img-fluid" alt="" />
        <img :src="icoBusiness2" class="img-fluid" alt="" />
        <img :src="icoBusiness3" class="img-fluid" alt="" />
      </figure>
    </div>
  </aside>
</template>

<script>
import logos from "@/assets/img/logos.png";
import {
  imageSign,
  icoBusiness1,
  icoBusiness2,
  icoBusiness3
} from "@/services/resources";

export default {
  name: "SideBarComponent",
  data: () => ({
    icoBusiness1,
    icoBusiness2,
    icoBusiness3,
    bgSiderBar: {
      backgroundImage: `url(${imageSign})`,
      height: "100vh",
      minHeight: "100%",
      backgroundSize: "cover"
    },
    path_logos: logos
  })
};
</script>

<style scoped lang="scss">
@import "./SideBar.scss";
</style>
