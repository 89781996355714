<template>
  <el-alert :title="title" :type="type" show-icon @close="close">
    <slot name="description">{{ description }}</slot>
  </el-alert>
</template>

<script>
export default {
  name: "AlertInfoComponent",
  data: () => ({}),
  methods: {
    close() {
      this.$emit("alertClose");
    }
  },
  props: {
    title: {
      default: "",
      type: String
    },
    description: {
      default: "",
      type: String
    },
    type: {
      default: "info"
    }
  }
};
</script>

<style></style>
